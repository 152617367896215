import {useState} from "react"
/**
 * @typedef {import('../components/base/modal').ModalProps} ModalProps
 */

export function useModalProps(defaultShow = false) {
  const [show, setShow] = useState(defaultShow)

  const closeModal = () => setShow(false)
  const openModal = () => setShow(true)

  /** @type {(props: ModalProps) => ModalProps} */
  const getProps = ({onCloseClick, ...props} = {}) => ({
    ...props,
    show,
    onCloseClick: onCloseClick
      ? () => {
          onCloseClick()
          closeModal()
        }
      : closeModal,
  })

  return {
    show,
    openModal,
    closeModal,
    getProps,
  }
}
