import * as React from "react"
import {navigate} from "gatsby"
import Loadable from "@loadable/component"

import IMG_LOGIN from "../images/img-login.svg"
import {Spacing} from "../components/base/spacing"
import {TextField} from "../components/base/text-field"
import {Button} from "../components/base/button"

import {useInput} from "../hooks/inputs"
import {useModalProps} from "../hooks/modal"
import {useSignInWorkspace} from "../hooks/gatsby-page"
import {useMutation} from "../hooks/react-query/useMutationV2"

import {checkWorkspace} from "../lib/smartworks-api/auth"

import AuthLayout from "../layouts/auth-layout"

const LoadableSignUpForm = Loadable(() =>
  import("../components/sign-up-hsforms-modal"),
)

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const SignInPage = props => {
  const workspace = useSignInWorkspace(props)

  // workspace input hooks
  const workspaceInput = useInput({
    defaultValue: workspace,
    validator: ({text}) => {
      if (!text) return "Please provide your workspace."
    },
  })

  // check workspace hook
  const [validateWorkSpace, {isLoading, error, reset, status}] = useMutation(
    checkWorkspace,
    {
      // if workspace is valid, navigate to signin account
      onSuccess: (_data, variables) => {
        navigate(`../signin-account`, {
          state: {
            workspace: variables.workspace,
            ...props.location.state,
          },
        })
      },
    },
  )

  const onSubmitHandler = e => {
    e.preventDefault && e.preventDefault()

    if (!workspaceInput.value) return
    validateWorkSpace({
      workspace: workspaceInput.value,
    })
  }

  // sign up dialog props
  const signUpDialog = useModalProps(false)

  usePagePrefetch(props)

  return (
    <AuthLayout bannerImg={IMG_LOGIN} seoTitle="Sign in to your workspace">
      <h1>Sign in to your workspace</h1>
      <Spacing spacing="none" height={68} />

      <form onSubmit={onSubmitHandler}>
        <TextField
          {...workspaceInput.getProps({
            "aria-label": "Workspace Name",
            tabIndex: "0",
            disabled: isLoading,
            error: error ? error.message : undefined,
            onChange: () => {
              if (status !== "idle") {
                reset()
              }
            },
          })}
        />
        <Spacing spacing="xxl-2" />
        <Button
          block
          tabIndex="0"
          loading={isLoading}
          disabled={!workspaceInput.value}
          onClick={onSubmitHandler}>
          Continue
        </Button>

        {/* <Spacing spacing="xxl-2" />
        <Button
          block
          onClick={() => {
            signOut().then(s => console.log("sign out"))
          }}>
          Log out
        </Button> */}
      </form>

      <footer>
        <p>
          Need to get your group started?{" "}
          <button
            onClick={e => {
              e.preventDefault()
              signUpDialog.openModal()
            }}>
            Sign up now
          </button>
        </p>
      </footer>
      <LoadableSignUpForm {...signUpDialog.getProps()} />
    </AuthLayout>
  )
}

export default SignInPage

/**
 * @typedef {object} LocationState
 * @property {string=} workspace
 */
/**
 * @typedef {import('gatsby').PageProps<object, object, LocationState>} PageProps
 */

/**
 * @param {PageProps} props
 */
const usePagePrefetch = props => {
  React.useEffect(() => {
    /* global ___loader */
    if (!props.location.pathname.startsWith("/app/")) {
      // console.log("page prefetch", props.location)
      const enqueue = window.___loader?.enqueue || ___loader?.enqueue
      enqueue && enqueue("../signin-account")
    }
  }, [props.location.pathname])
}
