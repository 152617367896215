import {useRef} from "react"

/**
 * @template D,C,L
 * @template {import('gatsby').PageProps<D,C,L>} P
 * @param {P} props
 */
export function useSearchParams(props) {
  let params = useRef(new URLSearchParams(props.location.search))
  return params.current
}

/**
 * @typedef {object} LocationState
 * @property {string=} workspace
 */
/**
 * @typedef {import('gatsby').PageProps<object, object, LocationState>} AuthPageProps
 */

/**
 * @param {AuthPageProps} props
 */
export function useSignInWorkspace(props) {
  const params = useSearchParams(props)
  return props.location.state?.workspace || params.get("workspace") || ""
}
